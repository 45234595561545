

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.creative_footer_dialog {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 0;
  .save_button {
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    transition: 0.1s;
    font-weight: 500;
    outline: 0;
    min-width: 140px;
  }
  .cancel_button {
    color: #fff;
    background-color: #606266;
    border-color: #606266;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    transition: 0.1s;
    font-weight: 500;
    outline: 0;
    min-width: 140px;
  }
}
.wrapper_of_images {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
  .main_main {
    .wrapper_two {
      width: 200px;
      height: 200px;
      background: #fff;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      .item {
        display: block;
        width: 40px;
        height: 40px;
      }
      // }
    }
    .text_images {
      display: flex;
      justify-content: center;
      gap: 6px;
    }
  }
}
.toggle-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* Adjust this value to increase/decrease spacing */
}
